/**
 * Define a person
 * @param {Object} params
 * @param {String} params.name
 * @param {String} params.gender
 * @param {Date} params.dob
 * @param {Date} params.dod
 */
function person({ name = "", gender = "male", dob, dod }) {
  return {
    name,
    gender,
    dob,
    dod,
  };
}

/**
 * Define a family or a unit in the graph/tree
 * @param {Object} params
 * @param {Object} params.person Person object as defined in person function
 * @param {Array} params.children
 * @param {Object} params.spouse Person object as defined in person function
 */
function family({ person, spouse, children = [] }) {
  return {
    person,
    spouse,
    children,
  };
}

var JOSEPH = family({ person: person({ name: "Joseph", dob: "19.03.1988" }) });
var AULA = family({
  person: person({ name: "Aula", gender: "female", dob: "20.07.1978" }),
});
var GRAZIELLA = family({
  person: person({ name: "Graziella", gender: "female", dob: "20.07.1978" }),
});

var CELESTE = family({
  person: person({ name: "Celeste", gender: "female", dob: "02.07.2020" }),
});
var ASHLEY = person({ name: "Ashley", gender: "female", dob: "15.02.1984" });
var ELIAS = family({
  person: person({ name: "Elias", dob: "10.10.1979" }),
  children: [CELESTE],
  spouse: ASHLEY,
});

var AYLA = family({
  person: person({ name: "Ayla", gender: "female", dob: "22.08.2020" }),
});
var LARITTA = person({ name: "Laritta", gender: "female", dob: "05.11.1984" });
var MASSOUD = family({
  person: person({ name: "Massoud", dob: "10.08.1981" }),
  children: [AYLA],
  spouse: LARITTA,
});

var NAZHA = person({ name: "Nazha", gender: "female", dob: "25.01.1962" });
var ZIAD = family({
  person: person({ name: "Ziad", dob: "02.08.1959" }),
  children: [AULA, GRAZIELLA, ELIAS, MASSOUD, JOSEPH],
  spouse: NAZHA,
});

var SANDRA = family({
  person: person({ name: "Sandra", gender: "female" }),
});
var JOEL = family({
  person: person({ name: "Joel" }),
});
var DALAL = person({ name: "Dalal", gender: "female" });
var JIHAD = family({
  person: person({ name: "Jihad" }),
  children: [SANDRA, JOEL],
  spouse: DALAL,
});

var MARIA = family({
  person: person({ name: "Maria", gender: "female" }),
});
var REBECCA = family({
  person: person({ name: "Rebecca", gender: "female" }),
});
var CLARA = family({
  person: person({ name: "Clara", gender: "female" }),
});
var ELIE = family({
  person: person({ name: "Elie" }),
});
var ROLA = person({ name: "Rola", gender: "female" });
var IMAD = family({
  person: person({ name: "Imad" }),
  children: [MARIA, REBECCA, CLARA, ELIE],
  spouse: ROLA,
});

var ROY = family({
  person: person({ name: "Roy" }),
});
var JOY = family({
  person: person({ name: "Joy" }),
});
var MIRA = family({
  person: person({ name: "Mira", gender: "female" }),
});
var MARLENE = person({ name: "Marlene", gender: "female" });
var SOUHAD = family({
  person: person({ name: "Souhad", dob: "14.06.1966" }),
  children: [ROY, MIRA, JOY],
  spouse: MARLENE,
});

var RYAN = family({
  person: person({ name: "Ryan" }),
});
var RALPH = family({
  person: person({ name: "Ralph" }),
});
var RAFI = family({
  person: person({ name: "Rafi" }),
});
var REINE = person({ name: "Reine", gender: "female" });
var IYAD = family({
  person: person({ name: "Iyad", dob: "01.03.1969" }),
  children: [RYAN, RALPH, RAFI],
  spouse: REINE,
});

var BASSEL = family({
  person: person({ name: "Bassel" }),
});
var CHARBEL = family({
  person: person({ name: "Charbel" }),
});
var LIONEL = family({
  person: person({ name: "Lionel" }),
});
var JANINE = person({ name: "Janine", gender: "female" });
var WIHAD = family({
  person: person({ name: "Wihad" }),
  children: [BASSEL, CHARBEL, LIONEL],
  spouse: JANINE,
});

var CARL = family({
  person: person({ name: "Carl" }),
});
var CHRIS = family({
  person: person({ name: "Chris" }),
});
var CARINE = person({ name: "Carine", gender: "female" });
var RACHAD = family({
  person: person({ name: "Rachad" }),
  children: [CARL, CHRIS],
  spouse: CARINE,
});

var HODA = family({
  person: person({ name: "Hoda", gender: "female", dob: "25.02.1956" }),
});

var SILVA = family({
  person: person({ name: "Silva", gender: "female" }),
});

var MIRVA = family({
  person: person({ name: "Mirva", gender: "female" }),
});
var NAZIRA = person({ name: "Nazira", gender: "female" });

var MASSOUD_GF = family({
  person: person({ name: "Massoud" }),
  children: [
    HODA,
    JIHAD,
    ZIAD,
    IMAD,
    SOUHAD,
    SILVA,
    IYAD,
    MIRVA,
    WIHAD,
    RACHAD,
  ],
  spouse: NAZIRA,
});

const rootNode = MASSOUD_GF;

export default rootNode;
