import { select, tree, hierarchy, linkVertical } from "d3";
import rootNode from "./data";
import "./index.css";
let app = document.querySelector("#app");
console.log(rootNode);
app.innerHTML = "<svg></svg>";

const svg = select("svg");
const width = document.body.clientWidth;
const height = Math.min(document.body.clientHeight, 850);
const fontSize = Math.max(12, width / 65);
const rectWidth = fontSize;
const rectHeight = 120;
const spaceRelation = rectWidth / 2;

const margin = { top: height / 13, right: 0, bottom: height / 13, left: 0 };
const innerWidth = width - margin.left - margin.right;
const innerHeight = height - margin.top - margin.bottom;

const treeLayout = tree().size([innerWidth, innerHeight]);

const zoomG = svg.attr("width", width).attr("height", height).append("g");

const g = zoomG
  .append("g")
  .attr("transform", `translate(${margin.left},${margin.top})`);

const root = hierarchy(rootNode);
const links = treeLayout(root).links();
const linkPathGenerator = linkVertical()
  .x((d) => (d.data.spouse !== undefined ? d.x - spaceRelation / 2 : d.x))
  .y((d) => d.y);

g.selectAll("path")
  .data(links)
  .enter()
  .append("path")
  .attr("d", linkPathGenerator);

const mainRects = g.selectAll(".mainRect").data(root.descendants());

const spouseRects = g.selectAll(".spouseRects").data(root.descendants());

const mainTexts = g.selectAll(".mainTexts").data(root.descendants());
const mainDOBTexts = g.selectAll(".mainDOBTexts").data(root.descendants());

const spouseTexts = g.selectAll(".spouseTexts").data(root.descendants());
const spouseDOBTexts = g.selectAll(".spouseDOBTexts").data(root.descendants());

// const relationLine = g.selectAll(".line").data(root.descendants());

// relationLine
//   .enter()
//   .append("line")
//   .attr("class", "line")
//   .attr("x1", (d) => d.x - spaceRelation)
//   .attr("x2", (d) => d.x + spaceRelation)
//   .attr("y1", (d) => d.y)
//   .attr("y2", (d) => d.y + 1)
//   .attr("stroke", "#56c2a3")
//   .classed("hide", (d) => d.data.spouse === undefined);

mainRects
  .enter()
  .append("rect")
  .attr("class", "mainRects")
  .attr("x", (d) => {
    return d.data.spouse !== undefined
      ? d.x - rectWidth / 2 - spaceRelation
      : d.x - rectWidth / 2;
  })
  .attr("y", (d) => d.y - rectHeight / 2)
  .attr("width", rectWidth)
  .attr("height", rectHeight)
  .attr("stroke-width", 1)
  .attr("stroke", "white")
  .attr("fill", (d) =>
    d.data.person.gender === "female" ? "#FFC0CB" : "#7f7fff"
  );

mainTexts
  .enter()
  .append("text")
  .attr("class", "mainTexts")
  .attr("x", (d) => {
    return d.data.spouse !== undefined ? d.x - spaceRelation : d.x;
  })
  .attr("y", (d) => d.y)
  .attr("text-anchor", "middle")
  .attr("fill", "white")
  .attr("font-size", fontSize + "px")
  .attr("writing-mode", "tb")
  .text((d) =>
    d.data.person.dob
      ? d.data.person.name + " - " + d.data.person.dob.substring(0, 5)
      : d.data.person.name
  );

// mainDOBTexts
//   .enter()
//   .append("text")
//   .attr("x", (d) => {
//     return d.data.spouse !== undefined
//       ? d.x - spaceRelation - rectWidth / 2 + fontSize / 2
//       : d.x - rectWidth / 2 + fontSize / 2;
//   })
//   .attr("y", (d) => d.y + 3)
//   .attr("text-anchor", "middle")
//   .attr("fill", "black")
//   .attr("class", "mainTexts")
//   .attr("font-size", fontSize / 1.4 + "px")
//   .attr("writing-mode", "tb")
//   .text((d) => d.data.person.dob);

spouseRects
  .enter()
  .append("rect")
  .attr("class", "spouseRects")
  .attr("x", (d) => d.x - rectWidth / 2 + spaceRelation)
  .attr("y", (d) => d.y - rectHeight / 2)
  .attr("width", rectWidth)
  .attr("height", rectHeight)
  .attr("stroke-width", 1)
  .attr("stroke", (d) =>
    d.data.spouse && d.data.spouse.gender === "female" ? "#FFC0CB" : "#7f7fff"
  )
  .attr("fill", "white")
  .classed("hide", (d) => d.data.spouse === undefined);

spouseTexts
  .enter()
  .append("text")
  .attr("class", "spouseTexts")
  .attr("x", (d) => d.x + spaceRelation)
  .attr("y", (d) => d.y)
  .attr("text-anchor", "middle")
  .attr("fill", "#666")
  .text((d) => {
    var spouse = d.data.spouse;
    if (!spouse) {
      return;
    }
    return spouse.dob
      ? spouse.name + " - " + spouse.dob.substring(0, 5)
      : spouse.name;
  })
  .attr("font-size", fontSize + "px")
  .attr("writing-mode", "tb")
  .classed("hide", (d) => d.data.spouse === undefined);

// spouseDOBTexts
//   .enter()
//   .append("text")
//   .attr("class", "spouseTexts")
//   .attr("x", (d) => d.x - rectWidth / 2 + spaceRelation + fontSize / 2)
//   .attr("y", (d) => d.y + 3)
//   .attr("text-anchor", "middle")
//   .attr("fill", "black")
//   .text((d) => {
//     return d.data.spouse && d.data.spouse.dob;
//   })
//   .attr("font-size", fontSize / 1.4 + "px")
//   .attr("writing-mode", "tb")
//   .classed("hide", (d) => d.data.spouse === undefined);
